import { User } from 'src/app/_models';
import { FeatureModel } from '../models/feature.model';

export interface StoreState {
  features: FeatureModel;
  user: User;
}

export enum StoreTypes {
  INIT = 'INIT',
  SET = 'SET',
  SET_USER = 'SET_USER',
  SET_FEATURE = 'SET_FEATURE',
  REMOVE = 'REMOVE',
  REMOVE_USER = 'REMOVE_USER',
  RESET = 'RESET',
  RESET_USER = 'RESET_USER',
  INIT_FEATURES = 'INIT_FEATURES'
}
