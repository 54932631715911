import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HopDebugToolComponent } from './hop-debug-tool.component';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';

@NgModule({
  declarations: [HopDebugToolComponent],
  imports: [CommonModule, MatMenuModule, MatButtonModule, MatIconModule, MatCheckboxModule],
  exports: [HopDebugToolComponent]
})
export class HopDebugToolModule {}
