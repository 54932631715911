import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { User } from '../../../_models';
import { environment } from '../../../../environments/environment';
import { Location } from './interfaces/location.model';
import { Observable, throwError } from 'rxjs';
import { LocationPaginationModel } from './interfaces/location.model';
import { catchError, map } from 'rxjs/operators';
import { MapLocation } from 'src/@hop/models/map.model';

@Injectable({ providedIn: 'root' })
export class LocationService {
  endpoint = `${environment.apiUrl}/locations`;

  constructor(private http: HttpClient) {}

  getAll(): Observable<LocationPaginationModel> {
    return this.http.get<LocationPaginationModel>(this.endpoint);
  }

  getAllAdmin(filter = {}, sortName = '', sortOrder = 'asc', page = 0, limit = 10): Observable<LocationPaginationModel> {
    page++; // to match the index on the backend
    return this.http
      .get<LocationPaginationModel>(this.endpoint + '/list-admin', {
        params: new HttpParams()
          .set('filter', JSON.stringify(filter))
          .set('sortName', sortName)
          .set('sortOrder', sortOrder)
          .set('page', page.toString())
          .set('limit', limit.toString())
      })
      .pipe(
        map((data) => {
          data.items = data.items.map((item) => new Location(item));
          return data;
        }),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  getDetailsById(id) {
    return this.http.get<Location>(this.endpoint + '/' + id);
  }

  update(location: Location) {
    return this.http.post<Location>(this.endpoint, location);
  }

  map(mapLocation: MapLocation) {
    return this.http.post<Location>(this.endpoint + '/map', mapLocation);
  }
}
