import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import * as cookie from 'js-cookie';



import { User } from '../_models';
import { environment } from '../../environments/environment';

@Injectable({providedIn: 'root'})
export class AuthenticationService {

  constructor(
    private router: Router,
    private http: HttpClient
  ) {
    const auth: AuthenticationPayload = JSON.parse(cookie.get('auth') || '{}');
    if (auth.user) {
      this.user$.next(auth.user);
      this.jwtToken$.next(auth.jwt.token);
      this.jwtRefreshToken$.next(auth.jwt.refreshToken);
      this.startRefreshTokenTimer();
      console.log(auth);
    }
  }

  public get user(): User {
    return this.user$.value;
  }

  user$: BehaviorSubject<User> = new BehaviorSubject<User>(null);
  jwtToken$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private jwtRefreshToken$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  public get jwtToken(): string {
    return this.jwtToken$.value;
  }

  // helper methods

  private refreshTokenTimeout;

  login(username: string, password: string): Observable<AuthenticationPayload> {
    return this.http.post<any>(`${environment.apiUrl}/login`, {username, password} // { withCredentials: true }
    )
      .pipe(map(data => {
        this.user$.next(data.user);
        this.jwtToken$.next(data.jwt.token);
        this.jwtRefreshToken$.next(data.jwt.refreshToken);
        cookie.set('auth', data, {expires: 183000});
        this.startRefreshTokenTimer();
        return data;
      }));
  }

  logout() {
    if (!this.jwtRefreshToken$.value) {
      return of(true);
    }
    this.http.post<any>(
      `${environment.apiUrl}/logout`,
      {refreshToken: this.jwtRefreshToken$.value}
      // { withCredentials: true }
    ).subscribe();
    this.stopRefreshTokenTimer();
    this.user$.next(null);
    this.jwtToken$.next(null);
    this.jwtRefreshToken$.next(null);
    cookie.remove('auth');
    // this.router.navigate(['/login']);
    location.reload();
  }

  refreshToken(): Observable<AuthenticationPayload> {
    if (!this.jwtRefreshToken$.value || this.jwtToken$.value) {
      return of(null);
    }
    return this.http.post<any>(
      `${environment.apiUrl}/refresh-token`, {refreshToken: this.jwtRefreshToken$.value} // { withCredentials: true }
    )
      .pipe(map((data) => {
        this.user$.next(data.user);
        this.jwtToken$.next(data.jwt.token);
        data.jwt.refreshToken = this.jwtRefreshToken$.value;
        cookie.set('auth', data, {expires: 183000});
        this.startRefreshTokenTimer();
        return data;
      }));
  }

  private startRefreshTokenTimer() {
    // parse json object from base64 encoded jwt token
    const jwtToken = JSON.parse(atob(this.jwtToken$.value.split('.')[1]));

    // set a timeout to refresh the token a minute before it expires
    const expires = new Date(jwtToken.exp * 1000);
    const timeout = expires.getTime() - Date.now() - (60 * 1000);
    this.refreshTokenTimeout = setTimeout(() => this.refreshToken().subscribe(), timeout);
  }

  private stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }
}

export interface AuthenticationPayload {
  user: User;
  isAdmin?: boolean;
  jwt: {
    token: string;
    refreshToken?: string;
  };
}
