import { BaseObjectModel } from 'src/@hop/models/base.model';

export class Location extends BaseObjectModel {
  name: string;
  nameEN?: string;
  type: number;
  uuid: string;
  slug: string;
  position: number;
  fromTour: number;
  enabled: boolean;
  children: Array<Location>;
  pathIds: string;
  path: string;
  images: Array<LocationImage>;
  description: LocationDescription;
  id: number;
  parentId: number;
  parent: Location;
  typeRo: string;

  constructor(location) {
    super(location, true);
    this.uuid = location?.uuid || '';
    this.name = location?.name || '';
    this.nameEN = location?.nameEN || null;
    this.type = location?.type || 2;
    this.dateCreated = location?.dateCreated || null;
    this.dateUpdated = location?.dateUpdated || null;
    this.slug = location?.slug || '';
    this.position = location?.position || 0;
    this.fromTour = location?.fromTour || null;
    this.enabled = location?.enabled || true;
    this.children = location?.children || [];
    this.path = location?.path || '';
    this.pathIds = location?.pathIds || '';
    this.images = location?.images || [];
    this.description = location?.description || {
      lang: 'ro',
      description: '',
      title: '',
      descriptionTarget: '',
      description2: '',
      descriptionShort: '',
      descriptionEntertaiment: '',
      dateCreated: null,
      dateUpdated: null
    };
    this.id = location?.id || 0;
    this.parentId = location?.parentId || 0;
    this.parent = location?.parent || null;
    switch (location?.type) {
      case 2:
        this.typeRo = 'Tara';
        break;
      case 4:
        this.typeRo = 'Regiune';
        break;
      case 9:
        this.typeRo = 'Oras';
        break;
      default:
        this.typeRo = 'Pamant';
        break;
    }
  }
}
export class LocationPaginationModel {
  items: Location[];
  meta: {
    currentPage: number;
    itemCount: number;
    itemsPerPage: number;
    totalItems: number;
    totalPages: number;
  };
}

export class LocationImage {
  image: string;
  thumb: string;
  size?: string;
  isMain?: boolean;
  title?: string;
  position?: number;
  dateCreated?: Date;
  dateUpdated?: Date;
  id?: string;
}

export class LocationDescription {
  title: string;
  lang: string;
  description: string;
  descriptionTarget?: string;
  description2?: string;
  descriptionEntertaiment?: string;
  descriptionShort: string;
  uuid?: string;
  dateCreated?: Date;
  dateUpdated?: Date;
}
