export class Utils {}

declare global {
  interface Object {
    _has(path: string): boolean;

    _get(path: string, fallback: any): any;
  }
}

Object.defineProperty(Object.prototype, '_has', {
  value: function (needle) {
    return !(this._get(needle) == null || typeof this._get(needle) === 'undefined');
  }
});

Object.defineProperty(Object.prototype, '_get', {
  value: function (needle, fallback) {
    let obj = this;
    let needles = needle.split('.');
    let needles_full = [];
    let needles_square;
    for (let i = 0; i < needles.length; i++) {
      needles_square = needles[i].split('[');
      if (needles_square.length > 1) {
        for (let j = 0; j < needles_square.length; j++) {
          if (needles_square[j].length) {
            needles_full.push(needles_square[j]);
          }
        }
      } else {
        needles_full.push(needles[i]);
      }
    }
    for (let i = 0; i < needles_full.length; i++) {
      let res = needles_full[i].match(/^((\d+)|"(.+)"|'(.+)')\]$/);
      if (res !== null) {
        for (let j = 0; j < res.length; j++) {
          if (res[j] !== undefined) {
            needles_full[i] = res[j];
          }
        }
      }

      if (obj[needles_full[i]] == null || typeof obj[needles_full[i]] === 'undefined') {
        return (typeof fallback !== 'undefined') ? fallback : undefined;
      }
      obj = obj[needles_full[i]];
    }
    return obj;
  }
});
