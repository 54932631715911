// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { features } from 'src/features/features';

const mainUrl = 'https://develop.topsejur.ro';
const sentryUrl = 'https://498a71a3a8ed4200981184505af67a6d@o1427981.ingest.sentry.io/6781241';
const isActivatedSentry = false;

export const environment = {
  production: false,
  apiUrl: mainUrl + '/api',
  apiUploadUrl: mainUrl + '/api',
  apiSocketUrl: mainUrl,
  sentryUrl,
  mainUrl,
  isActivatedSentry,
  features
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI..
