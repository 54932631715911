<h1 mat-dialog-title>{{data.title}}</h1>
<div mat-dialog-content>
  {{data.message}}
</div>
<div style="float:right;margin:20px;">
  <button (click)="delete()" [mat-dialog-close]="true" mat-button color="warn">
    <span>Confirm</span>
  </button>

  <button (click)="cancel()" [mat-dialog-close]="false" mat-raised-button color="primary">
    <span>Cancel</span>
  </button>
</div>
