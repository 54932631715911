import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FeatureModel } from '../models/feature.model';
import * as cookie from 'js-cookie';
import { ActivatedRoute } from '@angular/router';
import { ObservableStore } from '@codewithdan/observable-store';
import { StoreState, StoreTypes } from './store-state';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
// import { features } from 'process';

@Injectable({ providedIn: 'root' })
export class FeatureService extends ObservableStore<StoreState> {
  features$: BehaviorSubject<FeatureModel> = new BehaviorSubject(environment.features);
  constructor() {
    super({
      logStateChanges: true,
      trackStateHistory: true
    });
    const initialStates = {
      features: this.features$.value
    };
    this.setState(initialStates, StoreTypes.INIT_FEATURES);
    this.stateChanged.subscribe((state) => {
      this.features$.next(state.features);
    });
    const featureNames = Object.keys(this.features$.value);
    featureNames.forEach((name) => {
      const featureValue = this.features$.value[name];
      const hasFeatureCookie = cookie.get(`feature-${name}`);
      if (hasFeatureCookie) {
        const value = hasFeatureCookie === 'true';
        this.setValue(name, value);
      }
    });
    // console.log(this.formUtilsService.createFormFromObject(this.features));
  }

  public hasFeature(name: string) {
    return !!this.features$.value[name];
  }

  get hasPackage() {
    return this.hasFeature('hasPackage');
  }

  get isDebug() {
    return this.hasFeature('isDebug');
  }

  setCookie(params) {
    const keys = Object.keys(params)
      .filter((key) => key.match(/^feature-(.+)$/))
      .forEach((key) => {
        this.setValue(key.replace('feature-', ''), params[key]);
      });
  }

  setValue(name, value) {
    this.features$.value[name] = value;
    this.setState({ features: this.features$.value }, StoreTypes.SET_FEATURE + '_' + name);
    this.setCookieFromFeature(name, value);
  }

  setCookieFromFeature(name, value) {
    cookie.set(`feature-${name}`, value);
  }
}
